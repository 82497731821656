import { SvgIcon } from '@material-ui/core';
import { FC } from 'react';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Face.styles';

interface Props extends WithStyles<typeof styles> {
  color?: string;
}

const FaceClosedIconComponent: FC<Props> = ({ classes, color }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      classes={{ root: classes.root }}
    >
      <path
        d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        d="M7 8.6C7 8.03995 7 7.75992 7.10899 7.54601C7.20487 7.35785 7.35785 7.20487 7.54601 7.10899C7.75992 7 8.03995 7 8.6 7H13.4C13.9601 7 14.2401 7 14.454 7.10899C14.6422 7.20487 14.7951 7.35785 14.891 7.54601C15 7.75992 15 8.03995 15 8.6V13.4C15 13.9601 15 14.2401 14.891 14.454C14.7951 14.6422 14.6422 14.7951 14.454 14.891C14.2401 15 13.9601 15 13.4 15H8.6C8.03995 15 7.75992 15 7.54601 14.891C7.35785 14.7951 7.20487 14.6422 7.10899 14.454C7 14.2401 7 13.9601 7 13.4V8.6Z"
        stroke="#324DFC"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export const FaceClosedIcon = withStyles(styles)(FaceClosedIconComponent);
