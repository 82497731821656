import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      fill: 'transparent',
      fontSize: 13,
      color: '#324DFC',
      stroke: '#324DFC',
    },
  });
