import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';
export const styles = () =>
  createStyles({
    root: {
      color: colors.white.lightest,
      fontSize: 20,
      fill: 'transparent',
    },
  });
